import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import crossIcon from "../../assets/Icons/close.svg";
import styles from "./rowOrderDetails.module.css";
import { DataGrid } from "@mui/x-data-grid";
import { columns } from "./orderDetailcolumns.jsx";
import { supplyOrderDetailsColumn } from "./supplyOrderDetailsColumn";
import CustomNoRowsOverlay from "../../components/empty-grid-label/CustomNoRowsOverlay";
import HeaderTitle from "../page-Header/HeaderTitle";
import {
  HEADER_NAME,
  PO_Number,
  customerName,
  orderId
} from "./RowOrderDetailsConstant";
import PropTypes from 'prop-types';
import { NO_SUPPLY_LINE_MESSAGE } from "../../store/constants.js";
import { clearRowOrderDetails, clearNotificationObject} from "../../store/actions";
import { getNotificationsCount } from "../../store/actions/fetchData.js";
import { useNavigate } from "react-router";
import { UserContext } from "../AuthorizedPage/UserProvider.jsx";
import CustomPagination from "../../components/custom-pagination/CustomPagination";

const RowOrderDetails = ({ setShowOrderDetails, showOrderDetails, routeFrom = false,setNotificationOpen }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 840,
    borderRadius: 4,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  };
  const { rowOrderDetails, loading } = useSelector(
    (state) => state.orderReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signIn, signOut } = useContext(UserContext);
  const handleClose = () => {
    if(routeFrom){
      dispatch(getNotificationsCount(navigate, signIn, signOut));
      setNotificationOpen(false);
    }
    dispatch(clearNotificationObject());
    dispatch(clearRowOrderDetails());
    setShowOrderDetails(!showOrderDetails);
  };
  return (
    <div>
      <Modal
        onClose={handleClose}
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              height: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <HeaderTitle title={HEADER_NAME} />
            <Box onClick={handleClose}>
            <img
              src={crossIcon}
              alt="crossIcon"
              style={{ cursor: "pointer" }}
            />
            </Box>
          </Box>
          <Box
            sx={{
              height: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Typography
              sx={{
                width: "100%",
                fontFamily: "Source Sans Pro",
                fontSize: "20px",
                display: "flex",
                fontWeight: 600,
                lineHeight: "36px",
                letterSpacing: "0.20000000298023224px",
                color: "customColor.gray"
              }}
            >
              <span className={styles.orderNumber}>
                {orderId} {rowOrderDetails.orderSummary[0]?.orderNumber}
              </span>
              <span className={styles.purchaseOrderNumber}>
                {PO_Number}{" "}
                {rowOrderDetails.orderSummary[0]?.purchaseOrderNumber
                  ? rowOrderDetails.orderSummary[0]?.purchaseOrderNumber
                  : "--"}
              </span>
              <span className={styles.company}>
                {customerName} {rowOrderDetails.orderSummary[0]?.company.name}
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "Source Sans Pro",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "36px",
                letterSpacing: "0.20000000298023224px",
                color: "customColor.gray"
              }}
              component='div'
            >
              {" "}
              <Box
                sx={{
                  height: 160,
                  width: "100%"
                }}
              >
                <DataGrid
                  disableColumnSelector
                  disableColumnMenu
                  rows={rowOrderDetails?.orderSummary || []}
                  hideFooter
                  loading={false}
                  columns={columns}
                  pageSize={1}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                      }
                    }
                  }}
                />
              </Box>
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "Source Sans Pro",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "36px",
                letterSpacing: "0.20000000298023224px",
                color: "customColor.gray"
              }}
              component='div'
            >
              {" "}
              <Box
                sx={{height: 500,
                }}
              >
                <DataGrid
                    sx={{height: 500}}
                  disableSelectionOnClick
                  disableVirtualization
                  disableColumnSelector
                  loading={loading}
                  slots={{
                      pagination: CustomPagination, 
                      noRowsOverlay: CustomNoRowsOverlay
                  }}
                  slotProps={{
                    noRowsOverlay: {
                      message: NO_SUPPLY_LINE_MESSAGE
                    }
                  }}
                  rows={rowOrderDetails?.supplyLines}
                  columns={supplyOrderDetailsColumn || []}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 24 } },
                    columns: {
                      columnVisibilityModel: {
                      }
                    }
                  }}
                  pageSizeOptions={[5]}
                />
              </Box>
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
RowOrderDetails.propTypes = {
  setShowOrderDetails:PropTypes.func,
  setNotificationOpen:PropTypes.func,
  showOrderDetails:PropTypes.bool,
  routeFrom:PropTypes.string
};
export default RowOrderDetails;
